/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/

// Import Tailwind 

// // Third pirty style files
// // Sidebar Scroll


// // Flag icon
// @import '~flag-icon-css/css/flag-icon.min.css';


// // Rich Text Editor
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

// // Calendar

// // User tour



$mat-tab-header-active-label-text-color: #25b5c2;