.banner-con{
	min-height:480px;
	// background:url(../images/cab-banner.jpg) no-repeat center center;
	background-size:cover;
}
.banner-overlay{
	background-color:rgba(0,0,0,.3);
	position:absolute;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
}

.search-con {
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    min-height: 180px;
}
.s-txt-fld{
	border-radius:10px;
	border:1px #ABB7CC solid;
	padding:15px;
	min-height:100px;
	background-color:#FFF;
	cursor:pointer;
}
.s-txt-fld:hover{
	background-color:#F9F9F9;
}
.s-date-fld{
	border-radius:10px;
	border:1px #ABB7CC solid;
	min-height:100px;
	background-color:#FFF;
}
.date-link{
	cursor:pointer;
	padding:15px;
}
.dlink-left{
	border-top-left-radius:10px;
	border-bottom-left-radius:10px;
}
.dlink-right{
	border-top-right-radius:10px;
	border-bottom-right-radius:10px;
}
.date-link:hover{
	background-color:#F9F9F9;
}
.date-link .h4{
	background:url('#{$assets-base-url}images/flight/down-arrow.svg') no-repeat center right;
	background-size:12px;
}
.search-btn{
	font-size:16px;
	font-weight:600;
	margin-top:10px;
	position:relative;
	text-align:left;
	padding-left:18px;
	width:100%;
}
.search-btn::after{
	content:'';
	width:20px;
	height:20px;
	position:absolute;
	right:15px;
	top:12px;
	background:url('#{$assets-base-url}images/flight/right-arrow-w.svg') no-repeat center center;
	background-size:20px;
}
.d-swap-cab {
    width: 18px;
    height: 18px;
    background: url('#{$assets-base-url}assets/images/flight/desti-swap.svg') no-repeat center center;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    left: -22px;
    top: 40px;
}
.search-con label {
    font-size: 13px;
    font-weight: 600;
}
.search-con .form-control {
    height: 25px;
    font-size: 13px;
    line-height: 12px;
}
.select-bx {
    // background: #fff url(../images/down-arrow.svg) no-repeat center right 10px;
    background-size: 12px;
}
.ms-con{
	border:1px #b2bbcb solid;
	border-radius:6px;
	min-height:20px;
}
.ms-brd-right{
	border-right:1px #b2bbcb solid;
}
.ms-con .small{
	font-size:12px;
	margin-bottom:3px;
	color:#000;
	font-weight:500;
}
.ms-con .h6{
	font-size:14px;
}
.ms-btn{
	border:0px;
	padding:15px 0px;
	text-align:center;
	width:100%;
	color:#104A94;
	font-weight:500;
}
.ms-btn:hover{
	color:#104A94;
}
/*filter style*/
.filter-con{
	border:1px #b2bbcb solid;
	border-radius:8px;
	min-height:400px;
	font-size:14px;
	background-color:#fff;
	
}
.fltr-row{
	border-bottom:1px #b2bbcb solid;
}
.clear-fltr-link{
	cursor:pointer;
}
.fltr-row-head{
	font-size:14px;
	font-weight:600;
	cursor:pointer;
	// background:url(../images/down-arrow.svg) no-repeat center right;
	background-size:15px;
}
.fltr-row .s-f-check {
    width: 25px;
    height: 25px;
}
.fltr-row .form-check-label {
    margin-left: 10px;
    margin-top: 8px;
    font-size: 14px;
}
/*range slider style*/
.slider-con{
	position:relative;
	min-height:70px;
}
.rangebar{
	position:absolute;
	width:100%;
	height:2px;
	background-color:#0F468D;
	top:22px;
	left:0px;
	z-index:10;
}
.range-handler{
	width:20px;
	height:20px;
	border:2px #0F468D solid;
	border-radius:75%;
	position:absolute;
	top:13px;
	background-color:#fff;
	z-index:20;
}
.handler-left{
	left:0px;
}
.handler-right{
	right:0px;
}
.range-con{
	position:absolute;
	bottom:8px;
	left:0px;
	width:100%;
	font-weight:600;
}
.min-range{
	font-size:14px;
}
.max-range{
	font-size:14px;
}
.sr-list {
    background-color: #fff;
    border: 1px #b2bbcb solid;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
}
.specs{
	margin:0px;
	padding:0px;
	list-style:none;
	font-size:12px;
}

.share-icons{
	cursor:pointer;
	display:inline-block;
	margin:0 6px;
}
.vehicle-img{
	min-height:100px;
	background-color:#C5E2FA;
	background-repeat:no-repeat;
	background-position:center center;
	background-size:60%;
	border-radius:10px;
	overflow:hidden;
}
/*wizard style*/
.wizard-wrap{
	min-height:100px;
	position:relative;
}
.wizard-line{
	position:absolute;
	top:19px;
	left:0px;
	right:0px;
	width:92%;
	height:1px;
	border-bottom:2px #ABB7CC solid;
	z-index:90;
	margin:0 auto;
}
.wizard-line-completed{
	position:absolute;
	top:19px;
	left:50px;
	right:0px;
	height:1px;
	border-bottom:2px #104C98 solid;
	z-index:91;
}
.wizard-con{
	position:relative;
	z-index:100;
}
.wizard-con ul{
	list-style:none;
	padding:0px;
	margin:0px;
}
.wizard-con ul li{
	float:left;
	padding:0px;
	margin:0px 75px 0px 0px;
	width:123px;
}

.w-default-tick{
	width:40px;
	height:40px;
	border-radius:75%;
	border:2px #ABB7CC solid;
	background-color:#FFF;
	margin:0 auto;
	padding:6px;
	display:block;
	text-align:center;
}
.w-completed-tick{
	background: rgb(9,40,80);
	background: linear-gradient(0deg, rgba(9,40,80,1) 0%, rgba(17,80,160,1) 100%);
	border:0px;
	padding:7px;
	text-align:center;
}
.w-active-tick{
	border:2px #104C98 solid;
	padding:6px;
}
/*modify search pop*/
.modify-overlay{
	background-color:rgba(255,255,255,.8);
	position:fixed;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	z-index:9999;
	display:none;
}
.modify-window{
	position:fixed;
	top:10%;
	left:0px;
	right:0px;
	margin:0 auto;
	width:80%;
	min-height:200px;
	z-index:10000;
	padding:0px;
	border-radius:10px;
	display:none;
}
.mod-search{
	margin:0 auto;
}
/*search drops*/

.s-drop-con{
	position:absolute;
	left:0px;
	top:42px;
	width:360px;
	min-height:300px;
	border-radius:10px;
	background-color:#fff;
	padding:15px;
	z-index:100;
}
.pt-drop{
	right:0px;
	left:inherit;
	width:260px;
	min-height:80px;
}
.pt-drop .small{
	font-size:12px;
}
.pt-drop .select-bx{
	height:40px;
	margin-top:6px;
}
.drop-search{
	height:45px !important;
	// background:#fff url(../images/search.svg) no-repeat center right 10px;
	background-size:20px;
	font-size:16px !important;
}
.drop-select{
	height:45px !important;
	font-size:16px !important;
}
.s-result-con{
	margin-top:10px;
	border:1px #ABB7CC solid;
	border-radius:6px;
	overflow:hidden;
}

.s-result-con ul{
	list-style:none;
	padding:0px;
	margin:0px;
}
.s-result-con-cab li{
	padding:0px;
	margin:0px;
	display:block;
	padding:10px 10px 10px 10px;
	border-bottom:1px #ABB7CC solid;
	font-size:14px;
}
.s-result-con-cab li:last-child{
	border:0px;
}
.s-result-con-cab li:hover{
	background-color:#F9F9F9;
}
.btn-outline-primary-cab{
	border-color:#1150A0;
	border-radius:6px;
	font-size:12px;
	font-weight:600;
	text-shadow:none;
	color:#1150A0;
}
.btn-outline-primary-cab:hover{
	// color:#fff !important;
	color:#1150A0!important;
}
.bd-box{
	background-color:#fff;
	border:1px #b2bbcb solid;
	border-radius:6px;
	font-size:14px;
	font-weight:500;
}
.payment-bx{
	border:1px #ABB7CC solid;
	border-radius:6px;
	padding:10px;
	min-height:100px;
}
.payment-bx.active{
	border-color:#C02122;
}
.payment-bx.active .h6{
	color:#1150A0;
}
.payment-bx .pay-check {
    width: 35px;
    height: 35px;
}
.payment-bx .h6{
	font-size:14px;
}
.bx-pointer{
	  position:absolute;
	  top:-26px;
	  left:100px;
      width: 40px;
      height: 40px;
	//   background:url(../images/bx-pointer.svg) no-repeat center center;
}