$assets-base-url: "https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/";

.nav-link {
	font-size: 14px;
	font-weight: 600;
	color: #000;
}

.banner-section {}

.bnr-flight {
	position: absolute;
	width: 190px;
	height: 190px;
	top: 0px;
	right: 0px;
	background: url(#{$assets-base-url}images/flight/bnr-flight.png) no-repeat center center;
	background-size: contain;
	z-index: 10;
}

.bnr-fsearch {
	position: absolute;
	width: 395px;
	height: 200px;
	top: 0px;
	right: 100px;
	background: url('#{$assets-base-url}images/flight/bnr-flight-search.svg') no-repeat center center;
	background-size: contain;
	z-index: 20;
}

.bnr-holiday {
	position: absolute;
	width: 150px;
	height: 150px;
	top: 220px;
	right: 50px;
	background: url('#{$assets-base-url}images/holiday/bnr-holiday.png') no-repeat center center;
	background-size: contain;
	z-index: 10;
}

.bnr-hotel {
	position: absolute;
	width: 200px;
	height: 200px;
	top: 200px;
	right: 220px;
	background: url('#{$assets-base-url}images/hotel/bnr-hotel.png') no-repeat center center;
	background-size: contain;
	z-index: 10;
}

.mid-section {
	background: url('#{$assets-base-url}images/home/mid-sect-img.png') no-repeat center center;
	background-size: cover;
	min-height: 450px;
	position: relative;
}

.b-overlay {
	background-color: rgba(0, 0, 0, .8);
	width: 100%;
	height: 100%;
	position: absolute;
}

.blue-overlay {
	background-color: rgba(5, 26, 54, .8);
	width: 100%;
	height: 100%;
	position: absolute;
}

.red-overlay {
	background-color: rgba(192, 33, 34, .8);
	width: 100%;
	height: 250px;
	position: absolute;
	z-index: 100;
	bottom: 0px;
}

.booking-engine {
	background: url(#{$assets-base-url}images/home/booking-ngine.png) no-repeat center center;
	background-size: cover;
	min-height: 300px;
}

.distribution-solution {
	background: url('#{$assets-base-url}images/home/distribution.png') no-repeat center center;
	background-size: cover;
	min-height: 300px;
}

.white-label {
	background: url('#{$assets-base-url}images/home/tmc-whitelabel.png') no-repeat center center;
	background-size: cover;
	min-height: 300px;
}

.unifiedAPI {
	background: url('#{$assets-base-url}images/home/api.png') no-repeat center center;
	background-size: cover;
	min-height: 300px;
}

.btm-section {
	background: url('#{$assets-base-url}images/home/btm-section.png') no-repeat center center;
	background-size: cover;
	min-height: 250px;
	position: relative;
}

.black-overlay {
	background-color: rgba(0, 0, 0, .6);
	width: 100%;
	height: 100%;
	position: absolute;
}

/*login style*/
.login-window {
	background-color: #FFF;
	position: fixed;
	top: 20%;
	left: 0px;
	right: 0px;
	margin: 0 auto;
	width: 400px;
	width:550px;
	min-height: 200px;
	z-index: 10000;
	padding: 30px;
	border-radius: 10px;
}

.login-overlay {
	background-color: rgba(255, 255, 255, .8);
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
}

.border {
	border-color: #b3becf !important;
}

.login-window .small {
	font-size: 12px;
}

.login-window .form-control {
	height: 23px;
	font-size: 14px;
	color: #000;
}

.login-window a {
	color: var(--primary_color);
	text-decoration: none;
}

.login-window a:hover {
	color: var(--primary_color);
}

.biztrav-img {
	max-width: 800px;
}

@media (max-width:480px) {
	.login-window {
	  width: 394px !important;
	}
	.dial_code {
	  width: 100px!important;
	}
	.phone_number {
	  width: 215px !important;
	}
  }