/* You can add global styles to this file, and also import other style files */

// ng-select2
@import "~@ng-select/ng-select/themes/default.theme.css";

// flatpicker
@import "~flatpickr/dist/flatpickr.css";
@import "~sweetalert2/dist/sweetalert2.min.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

// Rating
.stars {
  font-size: 30px;
  color: var(--bs-gray);
}

.header {
  font-size: 1.3rem !important;
  font-weight: 500 !important;
  // padding: 5px !important;
}

::ng-deep .cdk-overlay-container {
  z-index: 1200 !important;
}

.star {
  position: relative;
  display: inline-block;
}

.star_fill {
  color: var(--bs-gray);
}

.full {
  color: #b0c4de;
}

.half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: var(--bs-gray);
}

.filled {
  color: #1e90ff;
}

.bad {
  color: #deb0b0;
}

.filled.bad {
  color: #ff1e1e;
}

.halfHeart {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: red;
}

.form-check-input {
  border: var(--bs-border-width) solid #bdb6b6 !important;
}

// File Upload
.dropzone_sec {
  border: 2px dashed #cdc6c6 !important;
}

.dropzone .dz-message {
  font-size: 24px;
  min-height: 150px !important;
  border: none !important;
  background: transparent !important;
  color: var(--bs-gray-400) !important;
}

.dropzone {
  border: 2px dashed var(--bs-border-color) !important;
}

dropzone > .dropzone.dz-wrapper {
  border: none !important;
}

.director-dialog {
  width: 55% !important;
  max-width: 90vw !important;
  margin-top: 90px !important;
  height: 541px !important;
}

.teams-dialog {
  width: 85% !important;
  max-width: 50vw;
  margin-top: 88px !important;
  // height: 520px !important;
  overflow: hidden !important;
  overflow-y: hidden !important;
}

// Range Slider
.ng5-slider .ng5-slider-pointer {
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  top: -8px !important;
  background-color: #5b73e8 !important;
  z-index: 3;
  border-radius: 16px;
}

.ng5-slider .ng5-slider-pointer:after {
  display: none !important;
}

.ng5-slider .ng5-slider-selection {
  background: #5b73e8 !important;
}

body[data-bs-theme="dark"] {
  .dropzone {
    background: transparent;
  }

  .ng5-slider .ng5-slider-bar {
    background: var(--bs-gray-300);
  }

  .accordion-button:not(.collapsed) {
    background-color: transparent !important;
  }

  // Select 2
  .ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value {
    background-color: #253a55 !important;
    color: #8495ab !important;
  }

  .ng-select .ng-select-container {
    background-color: var(--bs-input-bg) !important;
    border: 1px solid var(--bs-input-border-color);
    color: var(--bs-body-color);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: var(--bs-gray-100);
    color: var(--bs-body-color);
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    background-color: var(--bs-input-bg);
    color: var(--bs-body-color);
  }

  .ng-dropdown-panel {
    border: 1px solid var(--bs-gray-100);
  }

  // Sweet alert
  .swal2-popup {
    background: var(--bs-input-bg);
    color: var(--bs-body-color);
  }
}

.bg-flag {
  border-radius: 5px;
}

.icon-url {
  width: 77px;
  height: 75px;
  border-radius: 12px;
  margin: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.add-button {
  background: linear-gradient(0deg, rgb(72 94 117) 0%, rgb(29, 127, 222) 100%);
  color: #fff ;
  padding: 10px 30px;
  border: 0px;
}

.add-button:hover {
  color: #fff !important;
}

.bg-image:before {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0.05 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.content {
  margin-top: 5px !important;
  cursor: pointer;
  transition: transform ease 0.25s, box-shadow ease 0.5s !important;
}

.content:hover {
  transform: translateY(-5px) !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px !important;
}

.simplebar-scrollbar:before {
  width: 100%;
  background: #a2adb7 !important;
}

.cpr {
  cursor: pointer;
}

mat-snack-bar-container .mdc-snackbar__surface {
  background: none !important;
}

mat-snack-bar-container.custom-snackbar-success {
  background-color: rgb(89, 151, 83) !important;
  color: white !important;
  position: relative;
  top: 100px;
}

mat-snack-bar-container.custom-snackbar-error {
  background-color: rgb(128, 0, 21) !important;
  color: white !important;
  position: relative;
  top: 100px;
}

mat-snack-bar-container.custom-snackbar-success .mdc-button .mdc-button__label {
  color: white !important;
  /* This will only affect snackbar button labels */
}

mat-snack-bar-container.custom-snackbar-warning .mdc-button .mdc-button__label {
  color: white !important;
  /* This will only affect snackbar button labels */
}

mat-snack-bar-container.custom-snackbar-error .mdc-button .mdc-button__label {
  color: white !important;
  /* This will only affect snackbar button labels */
}

mat-snack-bar-container.custom-snackbar-warning {
  background-color: rgb(243, 169, 58) !important;
  color: white !important;
  position: relative;
  top: 100px;
}

mat-snack-bar-container.custom-snackbar-success::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  /* Height of the progress bar */
  background: rgba(253, 253, 252, 0.6);
  /* Color of the progress bar */
  transition: width 5s linear;
  /* Adjust duration as needed */
}

@keyframes progress-bar {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.animate-progress-bar::after {
  transform-origin: left;
  animation: progress-bar 5s forwards;
  /* Ensure duration matches snackbar duration */
}

.mat-autocomplete-panel {
  max-height: 300px;
  /* Adjust as needed */
  overflow: auto;
  /* Allow scrolling if suggestions are long */
}

.custom-autocomplete-panel {
  position: static !important;
}

.custom-snackbar-success .progress-bar {
  margin-top: 8px;
  /* Adjust margin to space from text */
}

.hori-timeline {
  .owl-nav {
    .owl-prev,
    .owl-next {
      background-color: rgba(91, 115, 232, 0.25) !important;
      color: #5b73e8 !important;
      border-radius: 50%;
    }
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  height: auto !important;
}

html,
body {
  background: #fff !important;
  height: 100%;
}

body {
  margin: 0;
  //font-family: Roboto, "Helvetica Neue", sans-serif;
}

.btn-danger {
  --bs-btn-color: #cf5a5a !important;
  --bs-btn-bg: white !important;
}

.btn-success:focus-visible::after {
  position: absolute;
  left: 50%;
  top: calc(100% + 12px);
  transform: translateX(-50%);
  content: "\21E7";
  animation: float 0.5s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateX(-50%) translatey(0px);
  }

  50% {
    transform: translateX(-50%) translatey(-6px);
  }

  100% {
    transform: translateX(-50%) translatey(0px);
  }
}

.mat-horizontal-stepper-header-container {
  background: #9696d5;
}

.mat-step-text-label {
  color: #fff;
}

.mat-step-icon {
  background-color: #7a7b7d !important;
}

.mat-step-icon-selected {
  background-color: #0ced20 !important;
}

//cloud animation styles
.cloud:before,
.cloud:after {
  content: "";
  border-radius: 50%;
}

.cloud:before {
  height: 40px;
  width: 40px;
  left: 1em;
  bottom: 0.5em;
}

.cloud:after {
  height: 50px;
  width: 50px;
  right: 1em;
  bottom: 0.4em;
}

.cloud,
.cloud:before,
.cloud:after {
  animation: cloudcolor;
  animation-direction: alternate;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  position: absolute;
}

.cloud {
  border-radius: 2em;
  height: 30px;
  width: 100px;
}

@keyframes cloudcolor {
  0% {
    background-color: #d4dde6;
  }

  100% {
    background-color: #95b3d2;
  }
}

.btn-flag-icon {
  height: 25px;
  width: 37px;
  border-radius: 0px;
  overflow: hidden;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.countrydrop-con {
  position: absolute;
  top: 50px;
  right: 15px;
  // width: 120px;
  width: 250px;
  height: 250px;
  z-index: 1000;
  background-color: #fff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px 18px;
}

.countrydrop-con ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.countrydrop-con li {
  list-style: none;
  padding: 0px;
  margin: 5px 0px 12px 0px;
}

.countrydrop-con li a {
  list-style: none;
  // padding: 0px 0px 0px 30px;
  margin: 0px;
  display: block;
  font-size: 14px;
  color: #000;
}

.countrydrop-con li a.active {
  color: #ce1624 !important;
  font-weight: 700;
}

.modal-body div.active {
  border: 4px solid #529ee5;
}

.countrydrop-con li a:hover {
  color: #ce1624;
  font-weight: 700;
}

mat-icon {
  cursor: pointer;
  color: red !important;
}

mat-icon.edit-icon {
  color: #24d158 !important;
}

.mat-mdc-text-field-wrapper {
  height: 37px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
}

.mat-mdc-form-field:not(.mat-form-field-disabled)
  .mat-mdc-floating-label.mdc-floating-label {
  top: 17px;
}

.mat-mdc-form-field
  .mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above {
  margin-bottom: -12px;
}

div.mat-mdc-autocomplete-panel {
  padding: 0px;
}

/* Increase clickable area */
.mat-checkbox-layout {
  padding: 12px;
}

.mat-checkbox-frame {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mat-checkbox-checkmark {
  width: 24px;
  height: 24px;
}

.mat-mdc-checkbox label {
  margin-top: 8px;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background {
  border-color: #2d8539 !important;
  background-color: #2d8539 !important;
}

.mat-mdc-card-header {
  padding: 0px !important;
}

::ng-deep a:hover .red-btn {
  color: #fff !important;
  /* Ensure this is not overridden */
}

.custom-dialog-panel {
  position: absolute !important;
  top: 10px;
  bottom: 10px;
  overflow: auto;
}

//ngx slider------------------------
.ngx-slider .ngx-slider-bar {
  height: 2px !important;
  background: #0f468d !important;
}

.ngx-slider .ngx-slider-selection {
  background: #0f468d !important;
}

.ngx-slider .ngx-slider-pointer {
  width: 20px !important;
  height: 20px !important;
  top: -4px !important;
  background-color: #fff !important;
  border: 2px #0f468d solid !important;
  border-radius: 10px !important;
  z-index: 6 !important;
}

.ngx-slider .ngx-slider-pointer:after {
  content: unset !important;
}

.ngx-slider .ngx-slider-bar-wrapper {
  margin-top: -11px !important;
}

.upload-icon {
  color: #5b74e9 !important;
}

.form-image {
  max-width: 50px;
  height: 35px;
}

.cusom-err {
  font-size: 12px;
  color: red !important;
}

mat-datepicker-toggle .mat-mdc-icon-button:hover {
  --mat-icon-button-state-layer-color: #fff !important;
}

mat-datepicker-toggle button {
  right: 5px;
  bottom: 6px;
}
.view-icon {
  color: #5b74e9 !important;
}

mat-slide-toggle.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-handle-color: #5b74e9 !important;
  --mdc-switch-selected-track-color: #5b74e9 !important;

  --mdc-switch-selected-focus-state-layer-color: #5b74e9 !important;
  --mdc-switch-selected-handle-color: #5b74e9 !important;
  --mdc-switch-selected-hover-state-layer-color: #5b74e9 !important;
  --mdc-switch-selected-pressed-state-layer-color: #5b74e9 !important;
  --mdc-switch-selected-focus-handle-color: #5b74e9 !important;
  --mdc-switch-selected-hover-handle-color: #5b74e9 !important;
  --mdc-switch-selected-pressed-handle-color: #5b74e9 !important;
  --mdc-switch-selected-focus-track-color: #5b74e9 !important;
  --mdc-switch-selected-hover-track-color: #5b74e9 !important;
  --mdc-switch-selected-pressed-track-color: #5b74e9 !important;
  --mdc-switch-selected-track-color: #5b74e9 !important;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #5b74e9 !important;
  --mdc-radio-disabled-unselected-icon-color: #5b74e9 !important;
  --mdc-radio-unselected-hover-icon-color: #5b74e9 !important;
  --mdc-radio-unselected-icon-color: #5b74e9 !important;
  --mdc-radio-unselected-pressed-icon-color: #5b74e9 !important;
  --mdc-radio-selected-focus-icon-color: #5b74e9 !important;
  --mdc-radio-selected-hover-icon-color: #5b74e9 !important;
  --mdc-radio-selected-icon-color: #5b74e9 !important;
  --mdc-radio-selected-pressed-icon-color: #5b74e9 !important;
  --mat-radio-ripple-color: #5b74e9 !important;
  --mat-radio-checked-ripple-color: #5b74e9 !important;
  --mat-radio-disabled-label-color: #5b74e9 !important;
}
.btn-success {
  --bs-btn-bg: #5b74e9 !important ;
  --bs-btn-border-color: #5b74e9 !important;
}

.success-span {
  color: green;
}

.font16px {
  font-size: 16px;
}
@media (max-width: 576px) {
  .full-w {
    width: 100%;
  }
}
$assets-base-url: "https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/";









