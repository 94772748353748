@media (max-width: 480px) {
.banner-con{
	min-height:780px;
}
		.filter-con{
	display:none;
	position:fixed;
	top:0px;
	right:0px;
	width:280px;
	height:100%;
	z-index:10000;
	overflow:scroll;
	border:0px;
	border-radius:0px;
}
.modify-btn {
    width: 26px;
    height: 18px;
    font-size: 12px;
    // background: url(../images/edit.svg) no-repeat center right;
    background-size: auto;
    background-size: 21px;
    cursor: pointer;
	margin:3px auto 0 auto;
  }
 .filter-btn {
    width: 26px;
    height: 18px;
    font-size: 12px;
    // background: url(../images/filter.svg) no-repeat center right;
    background-size: auto;
    background-size: 21px;
    cursor: pointer;
	margin:3px auto 0 auto;
  }
  .mod-search {
  width: 403px;
}
.modify-window{
	width:100%;
}
 .d-swap-bus {
    left: 0px;
    right: 0px;
    top: -22px;
    margin: 0 auto;
    background: #fff url('#{$assets-base-url}assets/images/flight/desti-swap.svg') no-repeat center center;
    // background: #fff url(../images/desti-swap.svg) no-repeat center center;
    background-size: auto;
    background-size: 15px;
    border-radius: 75%;
    border: 1px #ABB7CC solid;
    width: 35px;
    height: 35px;
  }
   .mob-list-top-bg {
    background-color: #F5F5F5;
    margin-top: 0px;
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 2px;
	min-height:85px;
	margin-bottom:8px;
  }
  .fare-con {
    border: 0px;
    position: absolute;
    top: 16px;
    right: 18px;
    width: 130px;
    text-align: right;
    justify-content: inherit;
    flex-direction: none;
    display: inherit;
    height: auto;
  }
   .fare-con .h5 {
    font-size: 16px;
    font-weight: 500 !important;
  }
  .viewfare-btn {
    padding: 0px 6px 2px 6px;
    height: 25px;
    margin: 2px 0px 0px auto;
    width: 78px;
  }
   .fare-con .form-check {
    margin-right: 0px;
    text-align: left;
    margin-top: 0px;
  }
   .share-label {
    font-size: 12px;
  }
  .berth::after{
	  width:16px;
	  height:8px;
  }
   .mod-search {
    width: 403px;
  }
}