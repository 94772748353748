/* =============
   General
============= */

html {
    position: relative;
    min-height: 100%;
}


// .h1,
// h1 {
//     font-size: 2.5rem;
// }

// .h2,
// h2 {
//     font-size: 2rem;
// }

// .h3,
// h3 {
//     font-size: 1.575rem;
// }

// .h4,
// h4 {
//     font-size: 1.35rem;
// }

// .h5,
// h5 {
//     font-size: 1.25rem;
// }

// .h6,
// h6 {
//     font-size: 0.9rem;
// }

hr {
    margin: 1rem 0;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: .25;
}

a {
    text-decoration: none !important;
}

label {
    font-weight: 500;
}

// blockquote

.blockquote {
    padding: 10px 20px;
    border-left: 4px solid var(--#{$prefix}border-color);
}

.blockquote-reverse {
    border-left: 0;
    border-right: 4px solid var(--#{$prefix}border-color);
    text-align: right;
}

// container

// .container,
// .container-lg,
// .container-md,
// .container-sm,
// .container-xl,
// .container-xxl {
//     @media (min-width: 1200px) {
//         max-width: 1140px;
//     }
// }

// row

.row {
    > * {
        position: relative;
    }
}

// labels

label {
    margin-bottom: $form-label-margin-bottom;
}
