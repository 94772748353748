/* Media Query for Mobile Devices */ 
@media (max-width: 480px) {
.navbar-collapse{
	flex-basis: 0;
    flex-grow: 0;
}
.navbar-nav{
	flex-direction:row;
}
.nav-link{
	padding:10px;
}
.banner-section {
  height: 870px;
}
.bnr-fsearch {
	width:315px;
}
.bnr-hotel{
	right:200px;
}
.bnr-holiday{
	right:23px;
}
.mid-section{
	height:570px;
}
.btm-section{
	min-height:920px;
	background-size: 100% 130%;
}
.red-overlay{
	height:150px;
	padding:0px 20px;
}
.red-overlay .h2{
	font-size:16px;
}
.blue-overlay{
	height:920px;
}
}

/* Media Query for low resolution  Tablets, Ipads */ 
@media (min-width: 481px) and (max-width: 1000px) { 
}
/* Media Query for Tablets Ipads portrait mode */ 
@media (min-width: 768px) and (max-width: 1024px){ 

} 
          
/* Media Query for Laptops and Desktops */ 
@media (min-width: 1025px) and (max-width: 1280px){ 

} 
          
/* Media Query for Large screens */ 
@media (min-width: 1281px) { 

} 