//
// accordion.scss
//

.custom-accordion {

    .card {
        +.card {
            margin-top: 0.5rem;
        }
    }

    a {

        &.collapsed {
            i.accor-down-icon {
                &:before {
                    content: "\F0140";
                }
            }
        }
    }

    .card-body {
        color: $text-muted;
    }
}


// theme dark

[data-bs-theme="dark"] {
    .accordion-button {
        &:after {
            background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$gray-500}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>"));
        }
    }
}

.checkout-accordion {
    .accordion-item {
        margin-bottom: 16px !important;
        border-bottom-left-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
        box-shadow: var(--#{$prefix}box-shadow);

        .accordion-button {

            &:not(.collapsed){
                background-color: transparent !important;
                box-shadow: none !important;
            }

            &:focus{
                border-color: transparent !important;
                box-shadow: none !important;
            }
        }
    }
}