// 
// _demos.scss
// 

// Lightbox 

.mfp-popup-form {
  max-width: 1140px;
}

// Modals

.bs-example-modal {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
  display: block;
}


// Icon demo ( Demo only )
.icon-demo-content {
  text-align: center;
  color: $gray-500;

  i {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 36px;
    font-size: 24px;
    margin: 0px auto 16px;
    border: 2px solid var(--#{$prefix}border-color);
    color: var(--#{$prefix}text-muted);
    border-radius: 4px;
    transition: all 0.4s;
  }

  .col-lg-4 {
    margin-top: 24px;

    &:hover {
      i {
        color: $white;
        background-color: $primary;
        border-color: $primary;
      }
    }
  }
}


// Grid

.grid-structure {
  .grid-container {
    background-color: $gray-100;
    margin-top: 10px;
    font-size: .8rem;
    font-weight: $font-weight-medium;
    padding: 10px 20px;
  }
}


// card radio

.card-radio {
  background-color: var(--#{$prefix}custom-white);
  border: 2px solid var(--#{$prefix}border-color);
  border-radius: $border-radius;
  padding: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  &:hover {
    cursor: pointer;
  }
}

.card-radio-label {
  display: block;
}


.card-radio-input {
  display: none;

  &:checked+.card-radio {
    border-color: $primary !important;

    &:before {
      content: '\e9c3';
      font-family: "unicons-line";
      position: absolute;
      top: 5px;
      right: 20px;
      font-size: 22px;
      color: $primary;
    }
  }
}

.navs-carousel {
  .owl-nav {
    margin-top: 16px;

    button {
      width: 30px;
      height: 30px;
      line-height: 28px !important;
      font-size: 20px !important;
      border-radius: 50% !important;
      background-color: rgba($primary, 0.25) !important;
      color: $primary !important;
      margin: 4px 8px !important;
    }
  }
}



.dragdrop-list {
  max-width: 100%;
  border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.dragdrop-box {
  padding: 20px 10px;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dragdrop-box:last-child {
  border: none;
}

.dragdrop-list.cdk-drop-list-dragging .dragdrop-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.dragdrop-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}