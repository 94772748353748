/* Media Query for Mobile Devices */
$assets-base-url: "https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/";
.desktop-view {
  display: block;
}

.mobile-view {
  display: none;
}

/* Media query for mobile screens (max-width of 767px) */
@media (max-width: 767px) {
  .desktop-view {
    display: none; /* Hide the desktop view on mobile */
  }

  .mobile-view {
    display: block; /* Show the mobile view on small screens */
  }
}
@media (max-width: 480px) {
  .common-popup {
    max-width: 400px !important;
  }
}

@media (max-width: 480px) {
  .banner-con {
    min-height: 930px;
  }

  .d-swap {
    left: 0px;
    right: 0px;
    top: -22px;
    margin: 0 auto;
    background: #fff url("#{$assets-base-url}images/flight/desti-swap.svg")
      no-repeat center center;
    background-size: 15px;
    border-radius: 75%;
    border: 1px #abb7cc solid;
    width: 35px;
    height: 35px;
  }

  .add-service-slider {
    width: 599px !important;
  }

  .search-btn {
    text-align: center;
  }

  .stop-image {
    width: 110px !important;
  }
}

@media (max-width: 481px) {
  .h1,
  h1 {
    font-size: calc(1.375rem + 1.5vw) !important;

    h2,
    .h2 {
      font-size: calc(1.325rem + 0.9vw) !important;
    }
  }

  .mob-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.4);
    display: none;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 1000px) {
  .h1,
  h1 {
    font-size: calc(1.375rem + 1.5vw) !important;
  }

  h2,
  .h2 {
    font-size: calc(1.325rem + 0.9vw) !important;
  }
}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {
}

/* Media Query for Large screens */
@media (min-width: 1281px) {
}
.add-button {
  color: #fff;
}
