@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$assets-base-url: "https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/";
body {
	font-family: "Poppins", sans-serif;
	color: #000;
}

a:hover {
	color: var(--primary_color) !important;
}

p {
	font-weight: 400;
	font-size: 14px;
}

.blue-text {
	color: var(--primary_color);
}

.blue-bg {
	background-color: var(--primary_color);
}

.blue-bg:hover {
	background-color: var(--primary_color);
}

.red-text {
	color: var(--secondary_color);
}

.red-bg {
	background-color: var(--secondary_color);
}

.red-bg:hover {
	background-color: var(--secondary_color);
}

.blue-btn {
	background: var(--blue-bg);
	background: linear-gradient(0deg, var(--blue-bg) 0%, var(--primary_color) 100%);
	color: #fff;
	padding: 10px 30px;
	border: 0px;
}

.blue-btn:hover {
	background: var(--blue-bg);
	background: linear-gradient(0deg, var(--blue-bg) 0%, var(--primary_color) 100%);
	color: #fff;
}

.red-btn {
	background: var(--red-bg);
	background: linear-gradient(0deg, var(--red-bg) 0%, var(--secondary_color) 100%);
	color: #fff;
	padding: 10px 30px;
	border: 0px;
}

.red-btn:hover {
	background: rgb(96, 17, 17);
	background: linear-gradient(0deg, var(--red-bg) 0%, var(--secondary_color) 100%);
	color: #fff;
}

.white-btn {
	background-color: #fff;
	color: var(--secondary_color);
	padding: 10px 30px;
}

.white-btn:hover {
	background-color: #fff;
	color: var(--secondary_color);
}

/*footer style starts here*/
.footer {
	border-top: 1px #ABB7CC solid;
}

.footer a {
	text-decoration: none;
	color: #000;
	margin: 0 6px;
	font-weight: 500;
}

.blue-gradient {
	background: var(--blue-bg);
	background: linear-gradient(0deg, var(--blue-bg) 0%, var(--primary_color) 100%);
}

/*inner header starts here*/
.header {}

.top-right-nav {
	font-size: 12px;
}

.top-right-nav a {
	text-decoration: none;
	color: #000;
}

.top-right-nav span.devider {
	padding: 0px 5px;
}

.inner-nav {
	border-top: 1px #ABB7CC solid;
	border-bottom: 1px #ABB7CC solid;
	margin-top: 10px;
	padding: 10px 0px;
}

.inner-nav ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.inner-nav li {
	float: left;
	padding: 0px;
	margin: 0px;
}

.inner-nav li a {
	// padding: 8px 19px;
	padding: 8px 13px;
	margin: 0px;
	display: block;
	color: #000;
	font-size: 14px;
	text-decoration: none;
	color: #000;
}

.inner-nav li a.active {
	background-color: #E6EAF1;
	border-radius: 20px;
	color: var(--primary_color);
	font-weight: 600;
}

.mob-nav-head {
	display: none;
}

.m-close-btn {
	display: none;
}

.prof-link {
	padding-right: 25px;
	background: url('#{$assets-base-url}images/home/down-arrow.svg') no-repeat center right 8px;
	background-size: 12px;
	cursor: pointer;
	position: relative;
}

.prof-pop {
	width: 200px;
	height: auto;
	//height:120px;
	position: absolute;
	top: 23px;
	right: 0px;
	background-color: #fff;
	padding: 10px 15px;
	z-index: 1000;
	border-radius: 10px;
	display: none;
}

.prof-pop ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.prof-pop li a {
	padding: 0px;
	margin: 0px;
	text-decoration: none;
	color: #000;
	padding: 8px 0px;
	display: block;
	text-align: left;
}

.submenu-link {
	background: url('#{$assets-base-url}images/home/down-arrow.svg') no-repeat top 15px right;
	background-size: 12px;
	cursor: pointer;
	position: relative;
}

.submenu-pop {
	width: 200px;
	min-height: 120px;
	position: absolute;
	top: 48px;
	right: 0px;
	background-color: #fff;
	padding: 10px 15px 15px 15px;
	z-index: 100;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	display: none;
	border-left: 1px #ABB7CC solid;
	border-right: 1px #ABB7CC solid;
	border-bottom: 1px #ABB7CC solid;
}

.submenu-pop ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.submenu-pop li {
	float: none;
}

.submenu-pop li a {
	padding: 0px;
	margin: 0px;
	text-decoration: none;
	color: #000;
	padding: 5px 0px;
	display: block;
	text-align: left;
	font-size: 13px;
}

/*inner container starts here*/
.inner-con {
	min-height: 1000px;
}

.form-control {
	border-color: #ABB7CC;
	box-shadow: none !important;
	height: 45px;
}

.select-bx {
	background: #fff url('#{$assets-base-url}images/home/down-arrow.svg') no-repeat center right 10px;
	background-size: 12px;
}

.form-check-input {
	border-color: #ABB7CC;
	box-shadow: none !important;
	cursor: pointer;
}

.form-check-input:checked {
	background-color: #fff;
	border-color: #ABB7CC;
}

.form-check-input:checked[type=checkbox] {
	background: #fff url('#{$assets-base-url}images/home/check.svg') no-repeat center center;
	background-size: 12px;
}

.form-check-input:checked[type=radio] {
	background: #fff url('#{$assets-base-url}images/home/check.svg') no-repeat center center;
	background-size: 12px;
}

.btn-outline-primary {
	border-color: var(--primary_color);
	border-radius: 6px;
	font-size: 18px;
	font-weight: 600;
	text-shadow: none;
	color: var(--primary_color);
}

/*thank you style*/
.tp-wrap {
	background-color: var(--primary_color);
	height: 100%;
	position: relative;
}

.tp-con {
	background-color: #fff;
	min-height: 400px;
}

.tp-wrap .small {
	font-size: 12px !important;
}

.sm-connect {
	border: 1px var(--primary_color) dashed;
	border-radius: 4px;
}