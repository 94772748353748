@media (max-width: 480px) {
.banner-con{
	min-height:780px;
}
		.filter-con{
	display:none;
	position:fixed;
	top:0px;
	right:0px;
	width:280px;
	height:100%;
	z-index:10000;
	overflow:scroll;
	border:0px;
	border-radius:0px;
}
.modify-btn {
    width: 26px;
    height: 18px;
    font-size: 12px;
    background: url('#{$assets-base-url}images/flight/edit.svg') no-repeat center right;
    background-size: auto;
    background-size: 21px;
    cursor: pointer;
	margin:3px auto 0 auto;
  }
 .filter-btn {
    width: 26px;
    height: 18px;
    font-size: 12px;
    background: url('#{$assets-base-url}images/flight/filter.svg') no-repeat center right;
    background-size: auto;
    background-size: 21px;
    cursor: pointer;
	margin:3px auto 0 auto;
  }
  .mod-search {
  width: 403px;
}
.modify-window{
	width:100%;
}
 .d-swap-cab {
    left: 0px;
    right: 0px;
    top: -22px;
    margin: 0 auto;
    background: #fff url('#{$assets-base-url}assets/images/flight/desti-swap.svg') no-repeat center center;
    background-size: auto;
    background-size: 15px;
    border-radius: 75%;
    border: 1px #ABB7CC solid;
    width: 35px;
    height: 35px;
  }
}