$assets-base-url: "https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/";

.banner-con{
	min-height:480px;
	//background:url(../images/search-banner-bg.jpg) no-repeat center center;
	background-size:cover;
}
.banner-overlay{
	background-color:rgba(0,0,0,.5);
	position:absolute;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
}
.rti-row {
    border-bottom: 2px #b2bbcb dashed;
}
.rti-fare-con {
    min-height:161px;
     padding-top:0px;
}
.p-brd {
    border-right: 1px #ABB7CC dashed;
}
.fare-con {
    // border-left: 2px #b2bbcb dashed;
    // position: relative;
    // text-align: center;
    // height: 100%;
    // justify-content: center;
    // flex-direction: column;
    // display: flex;
	border-left: 2px #b2bbcb dashed;
    position: relative;
    text-align: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    display: flex;

}
.confirm-bx {
    border: 1px #b2bbcb solid;
    border-radius: 6px;
}
.brd-btm {
    border-bottom: 1px #b2bbcb solid;
}
.fd-baggage {
    padding: 3px 0px 3px 22px;
    background: url('#{$assets-base-url}images/flight/bag.svg') no-repeat center left;
    background-size: 15px;
    font-size: 12px;
}
.fd-btm {
    border-top: 1px #b2bbcb dashed;
}
.cfare-bx {
    min-height: 150px;
}
.search-tab{
	background: rgb(9,40,80);
	background: linear-gradient(0deg, rgba(9,40,80,1) 0%, rgba(17,80,160,1) 100%);
	border-top-left-radius:10px;
	border-top-right-radius:10px;
	width:397px;
	overflow:hidden;
}
.search-tab ul{
	list-style:none;
	padding:0px;
	margin:0px;
}
.search-tab li{
	float:left;
	padding:10px 15px;
	color:#fff;
	font-size:14px;
	font-weight:500;
	text-align:center;
	border-right:1px #09274E solid;
	border-left:1px #3A659B solid;
	cursor:pointer;
}
.search-tab li:first-child{
	border-left:0px;
}
.search-tab li:last-child{
	border-right:0px;
}
.search-tab li.active{
	background:#FFF;
	color:#000;
	font-weight:600;
}
.search-con-flight{
	background-color:#FFF;
	padding:20px;
	border-bottom-left-radius:10px;
	border-bottom-right-radius:10px;
	border-top-right-radius:10px;
	min-height:200px;
	--bs-border-radius: 0.375rem!important

}
.s-txt-fld{
	border-radius:10px;
	border:1px #ABB7CC solid;
	padding:15px;
	min-height:100px;
	background-color:#FFF;
	cursor:pointer;
}
.s-txt-fld:hover{
	background-color:#F9F9F9;
}
.s-date-fld{
	border-radius:10px;
	border:1px #ABB7CC solid;
	min-height:100px;
	background-color:#FFF;
}
.d-swap{
	width:18px;
	height:18px;
	background:url('#{$assets-base-url}images/flight/desti-swap.svg') no-repeat center center;
	background-size:cover;
	cursor:pointer;
	position:absolute;
	// left:-20px;
	left:-9px;
	top:40px;
}
.date-link{
	cursor:pointer;
	padding:15px;
}
.dlink-left{
	border-top-left-radius:10px;
	border-bottom-left-radius:10px;
}
.dlink-right{
	border-top-right-radius:10px;
	border-bottom-right-radius:10px;
}
.date-link:hover{
	background-color:#F9F9F9;
}
.date-link .h4{
	background:url('#{$assets-base-url}images/flight/down-arrow.svg') no-repeat center right;
	background-size:12px;
}
.search-con-flight .form-control{
	height:25px;
	font-size:13px;
	line-height:12px;
}
.search-con-flight label{
	font-size:13px;
	font-weight:600;
}
.search-con-flight .form-check-margin{
	// margin-top:20px;
	margin-top: 31px;
}
.search-con-flight .form-check-label{
	margin-left:10px;
	margin-top:8px;
	font-size:12px;
}
.search-con-flight .s-f-check{
	width:25px;
	height:25px;
}
.search-btn{
	font-size:16px;
	font-weight:600;
	margin-top:10px;
	position:relative;
	text-align:left;
	padding-left:18px;
	width:100%;
}
.search-btn::after{
	content:'';
	width:20px;
	height:20px;
	position:absolute;
	right:15px;
	top:12px;
	background:url('#{$assets-base-url}images/flight/right-arrow-w.svg') no-repeat center center;
	background-size:20px;
}
.s-drop-con{
	position:absolute;
	left:0px;
	top:42px;
	width:360px;
	min-height:300px;
	border-radius:10px;
	background-color:#fff;
	padding:15px;
	z-index:100;
}
.s-drop-con-flight{
	position:absolute;
	left:0px;
	top:33px;
	width:450px;
	min-height:300px;
	border-radius:10px;
	background-color:#fff;
	padding:15px;
	z-index:100;
}
.pc-drop{
	right:0px;
	left:inherit;
}
.pc-drop .small{
	font-size:12px;
}
.pc-drop .select-bx{
	height:40px!important;
	margin-top:6px;
}
.drop-search{
	height:45px !important;
	background:#fff url('#{$assets-base-url}images/flight/search.svg') no-repeat center right 10px;
	background-size:20px;
	font-size:16px !important;
}
.s-result-con{
	margin-top:10px;
	border:1px #ABB7CC solid;
	border-radius:6px;
	overflow:hidden;
}

.s-result-con ul{
	list-style:none;
	padding:0px;
	margin:0px;
}
.s-result-con li{
	padding:0px;
	margin:0px;
	display:block;
	padding:10px 10px 10px 40px;
	border-bottom:1px #ABB7CC solid;
	background:url('#{$assets-base-url}images/flight/airplane.svg') no-repeat left 8px center;
	background-size:25px;
	font-size:14px;
}
.s-result-con li:last-child{
	border:0px;
}
.s-result-con li:hover{
	background-color:#F9F9F9;
}
/*wizard style*/
.wizard-wrap{
	min-height:100px;
	position:relative;
}
.wizard-line{
	position:absolute;
	top:19px;
	left:0px;
	right:0px;
	width:92%;
	height:1px;
	border-bottom:2px #ABB7CC solid;
	z-index:90;
	margin:0 auto;
}
.wizard-line-completed{
	position:absolute;
	top:19px;
	left:50px;
	right:0px;
	height:1px;
	border-bottom:2px #104C98 solid;
	z-index:91;
}
.wizard-con{
	position:relative;
	z-index:100;
}
.wizard-con ul{
	list-style:none;
	padding:0px;
	margin:0px;
}
.wizard-con ul li{
	float:left;
	padding:0px;
	margin:0px 75px 0px 0px;
	width:123px;
}

.w-default-tick{
	width:40px;
	height:40px;
	border-radius:75%;
	border:2px #ABB7CC solid;
	background-color:#FFF;
	margin:0 auto;
	padding:6px;
	display:block;
}
.w-completed-tick{
	background: rgb(9,40,80);
	background: linear-gradient(0deg, rgba(9,40,80,1) 0%, rgba(17,80,160,1) 100%);
	border:0px;
	padding:7px;
}
.w-active-tick{
	border:2px #104C98 solid;
	padding:6px;
}
.ms-con{
	border:1px #b2bbcb solid;
	border-radius:6px;
	min-height:20px;
}
.ms-brd-right{
	border-right:1px #b2bbcb solid;
}
.ms-con .small{
	font-size:12px;
	margin-bottom:3px;
	color:#000;
	font-weight:500;
}
.ms-con .h6{
	font-size:14px;
}
.ms-btn{
	border:0px;
	padding:15px 0px;
	text-align:center;
	width:100%;
	color:#104A94;
	font-weight:500;
}
.ms-btn:hover{
	color:#104A94;
}
.share-icons{
	cursor:pointer;
	display:inline-block;
	margin:0 6px;
}
/*filter style*/
.filter-con{
	border:1px #b2bbcb solid;
	border-radius:8px;
	min-height:400px;
	font-size:14px;
	background-color:#fff;
	
}
.fltr-row{
	border-bottom:1px #b2bbcb solid;
}
.clear-fltr-link{
	cursor:pointer;
}
.fltr-row-head{
	font-size:14px;
	font-weight:600;
	cursor:pointer;
	background:url('#{$assets-base-url}images/flight/down-arrow.svg') no-repeat center right;
	background-size:15px;
}
.fltr-row .s-f-check {
    width: 25px;
    height: 25px;
}
.fltr-row .form-check-label {
    margin-left: 10px;
    margin-top: 8px;
    font-size: 14px;
}
/*range slider style*/
.slider-con{
	position:relative;
	min-height:70px;
}
.rangebar{
	position:absolute;
	width:100%;
	height:2px;
	background-color:#0F468D;
	top:22px;
	left:0px;
	z-index:10;
}
.range-handler{
	width:20px;
	height:20px;
	border:2px #0F468D solid;
	border-radius:75%;
	position:absolute;
	top:13px;
	background-color:#fff;
	z-index:20;
}
.handler-left{
	left:0px;
}
.handler-right{
	right:0px;
}
.range-con{
	position:absolute;
	bottom:8px;
	left:0px;
	width:100%;
	font-weight:600;
}
.min-range{
	font-size:14px;
}
.max-range{
	font-size:14px;
}
/*search list style*/
.sort-con{
	background-color:#F5F9FF;
	border:1px #b2bbcb solid;
	font-size:14px;
	font-weight:600;
	border-radius:6px;
}
.sort-head{
	background-color:#104C98;
	color:#fff;
	font-weight:600;
	text-align:center;
	position:absolute;
	left:-1px;
	top:0px;
	width:80px;
	padding:6px;
	border-top-left-radius:6px;
	border-bottom-left-radius:6px;
}
.sort-head:after{
	position:absolute;
	width:10px;
	height:20px;
	top:7px;
	right:-9px;
	content:'';
	background:url('#{$assets-base-url}images/flight/right-blue-arrow.svg') no-repeat no-repeat center;
	background-size:10px;
}
.sort-link{
	padding:6px 0px;
	text-align:center;
	position:relative;
	cursor:pointer;
}
.sort-link::after{
	content:'';
	background:url('#{$assets-base-url}images/flight/down-arrow.svg') no-repeat center right;
	background-size:10px;
	width:10px;
	height:10px;
	position:absolute;
	top:11px;
	margin-left:5px;
}
.sr-list{
	background-color:#fff;
	border:1px #b2bbcb solid;
	border-radius:6px;
	font-size:14px;
	font-weight:500;
}
.viewfare-btn{
	padding:5px 14px;
	font-size:12px;
	margin:2px auto 2px auto;
	width:87px;
}
.viewfare-btn:hover{
	color:#fff;
}
.hidefare-btn{
	padding:5px 14px;
	font-size:12px;
	border-color:#0F468D;
	color:#0F468D;
	font-weight:600;
	background-color:#fff;
	margin: 2px auto 2px auto;
}
.hidefare-btn:hover{
	color:#0F468D;
	background-color:#fff;
	border: 1px solid #0F468D !important;
}
.fare-con{
	border-left:2px #b2bbcb dashed;
	position:relative;
	text-align:center;
}
.fare-con .form-check{
	min-height:0px;
	width:60px;
	margin:2px auto 0 auto;
}
.tick-top{
	position:absolute;
	top:-9px;
	left:-11px;
	width:20px;
	height:15px;
	background-color:#fff;
	border-left:1px #b2bbcb solid;
	border-right:1px #b2bbcb solid;
	border-bottom:1px #b2bbcb solid;
	border-bottom-left-radius:20px;
	border-bottom-right-radius:20px;
	z-index:10;
}
.tick-bottom{
	position:absolute;
	bottom:-9px;
	left:-11px;
	width:20px;
	height:15px;
	background-color:#fff;
	border-left:1px #b2bbcb solid;
	border-right:1px #b2bbcb solid;
	border-top:1px #b2bbcb solid;
	border-top-left-radius:20px;
	border-top-right-radius:20px;
	z-index:10;
}

/*fare details style */
.fare-details-con{
	background-color:#F5F5F5;
	border:1px #b2bbcb solid;
	border-radius:6px;
}
.fh-col{
	margin-top:129px;
	font-size:12px;
}
.f-info{
	margin-top:12px;
}
.f-info p{
	font-size:13px;
}
.fare-slider{
	overflow:hidden;
}
.fare-slider ul{
	padding:0px;
	margin:0px;
	list-style:none;
	width:1000px;
}
.fare-slider ul li{
	padding:0px;
	margin:0px 15px 0px 0px;
	float:left;
	background-color:#FFF;
	border:1px #b2bbcb solid;
	width:150px;
	border-radius:8px;
	min-height:300px;
}
.fare-title{
	display:block;
	text-align:center;
}
.fare-title .s-f-check{
	width:35px;
	height:35px;
	margin:0 auto;
}
.fare-title .s-f-check:checked[type=radio]{
	background-size:16px;
	border-color:#0F468D;
}
.fare-title .form-check-label {
    margin-top: 8px;
    font-size: 14px;
}
.fare-row{
	border-top:1px #b2bbcb dashed;
	border-bottom:1px #b2bbcb dashed;
	display:block;
}
.fare-more-dtls{
	display:block;
}
.fare-more-dtls a{
	color:#104C98;
}
.slider-controls{
	position:relative;
	height:30px;
}
.control-left{
	position:absolute;
	width:15px;
	height:18px;
	left:0px;
	top:0px;
	background:url('#{$assets-base-url}images/flight/fare-gray-arrow-left.svg') no-repeat center center;
	background-size:10px;
	z-index:10;
	cursor:pointer;
}
.control-left:hover{
	background:url('#{$assets-base-url}images/flight/fare-red-arrow-left.svg') no-repeat center center;
	background-size:10px;
}
.control-left.active{
	background:url('#{$assets-base-url}images/flight/fare-red-arrow-left.svg') no-repeat center center;
	background-size:10px;
}
.control-right{
	position:absolute;
	width:15px;
	height:18px;
	right:0px;
	top:0px;
	background:url('#{$assets-base-url}images/flight/fare-gray-arrow-right.svg') no-repeat center center;
	background-size:10px;
	z-index:10;
	cursor:pointer;
}
.control-right:hover{
	background:url('#{$assets-base-url}images/flight/fare-red-arrow-right.svg') no-repeat center center;
	background-size:10px;
}
.control-right.active{
	background:url('#{$assets-base-url}images/flight/fare-red-arrow-right.svg') no-repeat center center;
	background-size:10px;
}
.control-right-txt{
	position:absolute;
	top:-2px;
	right:23px;
	width:114px;
	font-size:14px;
	z-index:10;
}
.mob-list-top-bg{
	background-color:#fff;
}
/*modify search pop*/
.modify-overlay{
	background-color:rgba(255,255,255,.8);
	position:fixed;
	top:0px;
	left:0px;
	width:100%;
	height:100%;
	z-index:9999;
	display:none;
}
.modify-window{
	position:fixed;
	top:20%;
	left:0px;
	right:0px;
	margin:0 auto;
	width:80%;
	min-height:200px;
	z-index:10000;
	padding:0px;
	border-radius:10px;
	display:none;
}