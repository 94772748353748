$assets-base-url: "https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/";
@media (max-width: 480px) {
	.banner-con {
		min-height: 930px;
	}

	.flight-dtls {
		//background: url(../../../images/down-arrow.svg) no-repeat right 0px top 10px;
		background-size: 10px;
	}

	.d-swap {
		left: 0px;
		right: 0px;
		top: -22px;
		margin: 0 auto;
		//background:#fff url(../../../images/home/desti-swap.svg) no-repeat center center;
		background-size: 15px;
		border-radius: 75%;
		border: 1px #ABB7CC solid;
		width: 35px;
		height: 35px;
	}

	.search-btn {
		text-align: center;
	}

	.rti-fare-con {
		min-height: 80px;
		padding-top: 0px;
	}

	.fare-con {
		// border: 0px;
		// position: absolute;
		// top: 16px;
		// right: 18px;
		// width: 130px;
		// text-align: right;
		// z-index: 1;
		// border: 0px;
		// position: absolute;
		// top: 3px;
		// right: 18px;
		// width: 130px;
		// text-align: right;
		// justify-content: inherit;
		// flex-direction: none;
		// display: inherit;
		// height: auto;
		border: 0px;
		position: absolute;
		top: 9px;
		right: 18px;
		width: 130px;
		text-align: right;
		justify-content: inherit;
		flex-direction: none;
		display: inherit;
		height: auto;
		z-index: 10;
	}

	.cfare-bx {
		min-height: 70px;
	}

	.cfare-bx {
		min-height: 70px;
	}

	.fare-con .h5 {
		font-size: 16px;
		font-weight: 500 !important;
	}

	.viewfare-btn {
		// padding: 2px 6px;
		// padding: 0px 6px 2px 6px;
		// height: 25px;
		// margin: 2px 0px 0px auto;
		// width: 85px;
		padding: 0px 6px 2px 6px;
		height: 25px;
		margin: 2px 0px 0px auto;
		width: 80px;
	}

	.drt-f-con {
		min-height: 58px;
	}

	.hidefare-btn {
		padding: 2px 6px;
	}

	.mob-list-top-bg {
		// background-color: #F5F5F5;
		// margin-top: 0px;
		// padding: 10px;
		// border-radius: 6px;
		// margin-bottom: 2px;
		background-color: #F5F5F5;
		margin-top: 0px;
		padding: 10px;
		border-radius: 6px;
		margin-bottom: 2px;
		min-height: 80px;
	}

	.f-info p {
		font-size: 11px;
	}

	.share-label {
		font-size: 12px;
	}

	.fare-con .form-check {
		margin-right: 0px;
		text-align: left;
		margin-top: 0px;
	}

	.sort-pop {
		width: 130px;
		min-height: 120px;
		position: absolute;
		top: 28px;
		right: 0px;
		background-color: #fff;
		padding: 10px 15px;
		z-index: 100;
		border-radius: 6px;
		display: none;
	}

	.sort-pop ul {
		list-style: none;
		padding: 0px;
		margin: 0px;
	}

	.sort-pop li {
		padding: 0px;
		margin: 0px;
		text-decoration: none;
		color: #000;
		padding: 4px 0px;
		display: block;
		text-align: left;
		cursor: pointer;
		font-weight: 500;
		font-size: 12px;
	}

	.sort-pop li:hover {
		color: #0F468D !important;
	}

	.modify-btn {
		position: absolute;
		top: 8px;
		right: 90px;
		width: 88px;
		height: 18px;
		font-size: 12px;
		background: url('#{$assets-base-url}images/flight/edit.svg') no-repeat center right;
		background-size: 21px;
		cursor: pointer;
	}

	.drt-vf-btn {
		font-size: 10px;
		padding: 2px 6px;
	}

	.filter-btn {
		position: absolute;
		top: 8px;
		right: 10px;
		width: 56px;
		height: 18px;
		font-size: 12px;
		background: url('#{$assets-base-url}images/flight/filter.svg') no-repeat center right;
		background-size: 21px;
		cursor: pointer;
	}

	.filter-con {
		display: none;
		position: fixed;
		top: 0px;
		right: 0px;
		width: 280px;
		height: 100%;
		z-index: 10000;
		overflow: scroll;
		border: 0px;
		border-radius: 0px;
	}

	.modify-window {
		width: 100%;
		top: 5%;
		padding: 10px;
	}

	.drt-result-pop {
		height: 322px !important;
	}

	// .mat-mdc-dialog-component-host > div{
	// 	height: 540px!important;
	// }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 1000px) {}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {}

/* Media Query for Large screens */
@media (min-width: 1400px) {
	.wizard-con ul li {
		width: 137px !important;
	}

	.wizard-line {
		width: 77% !important
	}
}