/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#d1202f 90%,#0000);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
  margin:15px auto 0 auto;
}
.loader-overlay{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    background-color: rgba(255, 255, 255, .7);
    z-index: 9999;
}
.processing-loader{
	position:fixed;
	top:35%;
	left:0px;
	right:0px;
	margin:0 auto;
	width:200px;
	min-height:100px;
	z-index:10000;
	background-color:#fff;
	padding:20px;
	text-align:center;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}