$assets-base-url: "https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/";

.banner-con {
	min-height: 480px;
	background: url('#{$assets-base-url}assets/images/flight/search-banner-bg.jpg') no-repeat center center;
	background-size: cover;
}

.banner-overlay {
	background-color: rgba(0, 0, 0, .5);
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 1 !important;
}

.dom-rt-con .h6 {
	font-size: 14px !important;
}

.stop-image {
	width: 150px !important;
}

.search-tab {
	background: var(--blue-bg);
	background: linear-gradient(0deg, var(--blue-bg) 0%, var(--primary_color) 100%);
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	width: 397px;
	overflow: hidden;
}
.cancelled{
	// margin-left:30px;
	color:#d53108;
	// background-color: #FFF3CD
}
.search-tab ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.search-tab li {
	float: left;
	padding: 10px 15px;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	border-right: 1px #09274E solid;
	border-left: 1px #3A659B solid;
	cursor: pointer;
}

.search-tab li:first-child {
	border-left: 0px;
}

.search-tab li:last-child {
	border-right: 0px;
}

.search-tab li.active {
	background: #FFF;
	color: #000;
	font-weight: 600;
}

.list-btm {
	border-top: 1px #CCCCCC dashed;
}

.search-con-flight {
	background-color: #FFF;
	padding: 20px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-top-right-radius: 10px;
	min-height: 200px;

}

.s-txt-fld {
	border-radius: 10px;
	border: 1px #ABB7CC solid;
	padding: 15px;
	min-height: 100px;
	background-color: #FFF;
	cursor: pointer;
}

.s-txt-fld:hover {
	background-color: #F9F9F9;
}

.s-date-fld {
	border-radius: 10px;
	border: 1px #ABB7CC solid;
	min-height: 100px;
	background-color: #FFF;
}

.d-swap {
	width: 18px;
	height: 18px;
	background: url('#{$assets-base-url}assets/images/flight/desti-swap.svg') no-repeat center center;
	background-size: cover;
	cursor: pointer;
	position: absolute;
	left: -20px;
	top: 40px;
}

.date-link {
	cursor: pointer;
	padding: 15px;
}

.dlink-left {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}

.dlink-right {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

.date-link:hover {
	background-color: #F9F9F9;
}

.date-link .h4 {
	background: url('#{$assets-base-url}assets/images/flight/down-arrow.svg') no-repeat center right;
	background-size: 12px;
}

.search-con-flight .form-control {
	height: 30px;
	font-size: 13px;
	line-height: 12px;
}

.search-con-flight label {
	font-size: 13px;
	font-weight: 600;
}

.search-con-flight .form-check-margin {
	margin-top: 31px;
}

.search-con-flight .form-check-label {
	margin-left: 10px;
	margin-top: 8px;
	font-size: 12px;
}

.search-con-flight .s-f-check {
	width: 25px;
	height: 25px;
}

.search-btn {
	font-size: 16px;
	font-weight: 600;
	margin-top: 10px;
	position: relative;
	text-align: left;
	padding-left: 18px;
	width: 100%;
}

.search-btn::after {
	content: '';
	width: 20px;
	height: 20px;
	position: absolute;
	right: 15px;
	top: 12px;
	background: url('#{$assets-base-url}assets/images/flight/right-arrow-w.svg') no-repeat center center;
	background-size: 20px;
}

.s-drop-con {
	position: absolute;
	left: 0px;
	top: 42px;
	width: 360px;
	min-height: 300px;
	border-radius: 10px;
	background-color: #fff;
	padding: 15px;
	z-index: 100;
}

.pc-drop {
	right: 0px;
	left: inherit;
}

.pc-drop .small {
	font-size: 12px;
}

.pc-drop .select-bx {
	height: 40px !important;
	margin-top: 6px;
}

.drop-search {
	height: 45px !important;
	background: #fff url('#{$assets-base-url}assets/images/flight/search.svg') no-repeat center right 10px;
	background-size: 20px;
	font-size: 16px !important;
}

.s-result-con {
	margin-top: 10px;
	border: 1px #ABB7CC solid;
	border-radius: 6px;
	overflow: hidden;
}

.s-result-con ul {
	list-style: none;
	padding: 0px;
	margin: 0px;
}

.s-result-con li {
	padding: 0px;
	margin: 0px;
	display: block;
	padding: 10px 10px 10px 40px;
	border-bottom: 1px #ABB7CC solid;
	background: url('#{$assets-base-url}assets/images/flight/airplane.svg') no-repeat left 8px center;
	background-size: 25px;
	font-size: 14px;
}

.s-result-con li:last-child {
	border: 0px;
}

.s-result-con li:hover {
	background-color: #F9F9F9;
}

// booking page starts
.fd-baggage {
	padding: 3px 0px 3px 22px;
	background: url('#{$assets-base-url}assets/images/bag.svg') no-repeat center left;
	background-size: 15px;
	font-size: 12px;
}

.fw-bold {
	font-weight: 700 !important;
}

.bd-box {
	background-color: #fff;
	border: 1px #b2bbcb solid;
	border-radius: 6px;
	font-size: 14px;
	font-weight: 500;
}

.sr-list {
	background-color: #fff;
	border: 1px #b2bbcb solid;
	border-radius: 6px;
	font-size: 14px;
	font-weight: 500;
}

.fd-btm {
	border-top: 1px #b2bbcb dashed;
}

.nav-pills .nav-link.active {
	background-color: var(--primary_color);
}

.nav-pills .nav-link {
	background-color: #E2E2E2;
	color: #000;
	font-weight: 600;
	border-radius: 5px;
}

.add-service-slider-con {
	overflow: hidden;
}

.add-service-slider {
	padding: 0px;
	margin: 0px;
	list-style: none;
	width: 1080px;
}

.add-service-bx {
	border: 1px #818C9F solid;
	border-radius: 6px;
	padding: 10px;
	min-height: 100px;
	width: 350px !important;
	float: left;
	margin-right: 10px;
}

.add-position {
	left: 5px !important;
}

.add-positionlarge {
	left: 8px !important;
}

.add-service-bx.active {
	border-color: var(--secondary_color);
}

.add-service-bx .as-check {
	width: 35px;
	height: 35px;
}

.add-service-bx .as-label {
	margin-top: 10px;
	margin-left: 10px;
}

.add-service-bx .small {
	font-size: 12px;
}

.ad-service-cr {
	top: -30px;
}

.ad-service-cl {
	top: -30px;
	left: inherit;
	right: 50px;
}

.bx-pointer {
	position: absolute;
	top: -26px;
	left: 25px;
	width: 40px;
	height: 40px;
	background: url('#{$assets-base-url}assets/images/bx-pointer.svg') no-repeat center center;
}

.insurence-bx {
	width: 270px !important;
	padding: 0px;
}

.ins-provider {
	border-bottom: 1px #818C9F dashed;
}

.payment-bx {
	border: 1px #ABB7CC solid;
	border-radius: 6px;
	padding: 10px;
	min-height: 100px;
}

.payment-bx.active {
	border-color: var(--secondary_color);
}

.payment-bx.active .h6 {
	color: var(--primary_color);
}

.payment-bx .pay-check {
	width: 35px;
	height: 35px;
}

.payment-bx .h6 {
	font-size: 14px;
}

.p-brd {
	border-right: 1px #ABB7CC dashed;
}

.confirm-bx {
	border: 1px #b2bbcb solid;
	border-radius: 6px;
}

.brd-btm {
	border-bottom: 1px #b2bbcb solid;
}

.cfare-bx {
	min-height: 150px;
}

.control-right_book {
	top: none !important;
}

.control-left_book {
	left: none !important
}

.control-left-book {
	position: absolute;
	width: 15px;
	height: 18px;
	// left:0px;
	// top:0px;
	background: url('#{$assets-base-url}images/fare-gray-arrow-left.svg') no-repeat center center;
	background-size: 10px;
	z-index: 10;
	cursor: pointer;
}

.control-left-book:hover {
	background: url('#{$assets-base-url}images/fare-red-arrow-left.svg') no-repeat center center;
	background-size: 10px;
}

.control-left-book.active {
	background: url('#{$assets-base-url}images/fare-red-arrow-left.svg') no-repeat center center;
	background-size: 10px;
}

.control-right-book {
	position: absolute;
	width: 15px;
	height: 18px;
	right: 0px;
	// top:0px;
	background: url('#{$assets-base-url}images/fare-gray-arrow-right.svg') no-repeat center center;
	background-size: 10px;
	z-index: 10;
	cursor: pointer;
}

.control-right-book:hover {
	background: url('#{$assets-base-url}images/fare-red-arrow-right.svg') no-repeat center center;
	background-size: 10px;
}

.control-right-book.active {
	background: url('#{$assets-base-url}images/fare-red-arrow-right.svg') no-repeat center center;
	background-size: 10px;
}

.control-right-txt {
	position: absolute;
	top: -2px;
	right: 23px;
	width: 114px;
	font-size: 14px;
	z-index: 10;
}

.sort-linkup::after {
	content: '';
	background: url('#{$assets-base-url}images/dropdown-arrow.svg') no-repeat center center;
	width: 20px;
	height: 20px;
	position: absolute;
	transform: rotate(180deg);
	margin-left: 6px;
}

.sort-linkup {
	cursor: pointer;
	//font-size: 12px;
}

.epopup-overlay {
	background-color: rgba(255, 255, 255, .8);
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	display: none;
}

// booking page Ends




/*common pop style*/

.common-popup{
	max-width:800px;
	min-width:319px;
	min-height:400px;
	background-color:#fff;
	position:fixed;
	left:0px;
	right:0px;
	margin:0 auto;
	top:5%;
	z-index:10002;
	border-radius:20px;
	// display:none;
	
}

.pop-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    background: url('#{$assets-base-url}images/flight/close.svg') no-repeat center center;
    cursor: pointer;
}

.commonpop-overlay{
	position:fixed;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	z-index:10001;
	background-color:rgba(0,0,0,.8);
	// display:none;
}

.commoninnerpop-con{
	overflow-y:scroll;
	overflow-x:none;
	height:500px;
}
.common-pop-mid{
	overflow-y:scroll;
	height:400px;
}
.t-logo{
	width:130px;
}
.ticket-con p{
	font-size:12px !important;
}
.ticket-con .small{
	font-size:11px !important;
}
.ticket-con .table{
	font-size:14px;
}
.common-pop-mid th{
	font-size:12px !important;
}
.common-pop-mid td{
	font-size:12px !important;
}
.poptop-actions .btn{
	font-size:12px 
}
.poptop-actions .form-control{
	height:35px;
}
.edit-markup-btn{
	font-size:14px !important;
}
.common-pop-mid th {
  font-size: 12px !important;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
.ticket-con p {
  font-size: 12px !important;
}
.common-pop-mid td {
    font-size: 12px !important;
}
.ticket-con .table {
    font-size: 14px;
}
.btn:hover {
	border-color: #1150A0!important;
}

